html, body, #root, #root>div.login-form {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.scrollable {
  display: block!important;
  overflow: auto;
  height: 200px;
}

.content {
  min-height: calc(100vh - 136px);
}
.footer {
  height: 50px;
}